var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tCheckStation" },
    [
      _c(
        "Row",
        { staticStyle: { "flex-wrap": "nowrap" }, attrs: { gutter: 4 } },
        [
          _vm.expand
            ? _c(
                "Col",
                { style: _vm.leftSpan },
                [
                  _c("Alert", { staticClass: "alert" }, [_vm._v("人员列表")]),
                  _c("Card", { staticClass: "card" }, [
                    _c(
                      "div",
                      { staticStyle: { overflow: "hidden", height: "100%" } },
                      [
                        _c(
                          "Row",
                          {
                            staticStyle: {
                              "margin-bottom": "10px",
                              display: "flex",
                            },
                          },
                          [
                            _c(
                              "RadioGroup",
                              {
                                staticStyle: { width: "50%" },
                                on: { "on-change": _vm.radioCheckChange },
                                model: {
                                  value: _vm.isCheck,
                                  callback: function ($$v) {
                                    _vm.isCheck = $$v
                                  },
                                  expression: "isCheck",
                                },
                              },
                              [
                                _c("Radio", { attrs: { label: 0 } }, [
                                  _c("span", [_vm._v("未检")]),
                                ]),
                                _c("Radio", { attrs: { label: 1 } }, [
                                  _c("span", [_vm._v("已检")]),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  width: "50%",
                                  "text-align": "right",
                                },
                              },
                              [
                                _c(
                                  "i-switch",
                                  {
                                    staticStyle: { "text-align": "center" },
                                    attrs: { size: "large" },
                                    on: { "on-change": _vm.typeChange },
                                    model: {
                                      value: _vm.typeStatus,
                                      callback: function ($$v) {
                                        _vm.typeStatus = $$v
                                      },
                                      expression: "typeStatus",
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { attrs: { slot: "open" }, slot: "open" },
                                      [_vm._v("复查")]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        attrs: { slot: "close" },
                                        slot: "close",
                                      },
                                      [_vm._v("非复查")]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm.queryTime
                          ? _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "flex-wrap": "nowrap",
                                  "align-items": "center",
                                },
                              },
                              [
                                _c(
                                  "RadioGroup",
                                  {
                                    staticClass: "btngroup",
                                    attrs: {
                                      type: "button",
                                      size: "small",
                                      "button-style": "solid",
                                    },
                                    on: { "on-change": _vm.pick },
                                    model: {
                                      value: _vm.date,
                                      callback: function ($$v) {
                                        _vm.date = $$v
                                      },
                                      expression: "date",
                                    },
                                  },
                                  [
                                    _c("Radio", { attrs: { label: "当日" } }),
                                    _c("Radio", { attrs: { label: "当月" } }),
                                    _c(
                                      "DatePicker",
                                      {
                                        attrs: {
                                          open: _vm.datePickerShow,
                                          transfer: "",
                                          type: "daterange",
                                        },
                                        on: {
                                          "on-change": _vm.datePickerChange,
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          [
                                            [
                                              _c("Radio", {
                                                attrs: { label: "自定义" },
                                                nativeOn: {
                                                  click: function ($event) {
                                                    _vm.datePickerShow =
                                                      !_vm.datePickerShow
                                                  },
                                                },
                                              }),
                                            ],
                                          ],
                                          2
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "span",
                                  {
                                    staticClass: "resetBtn",
                                    staticStyle: { cursor: "pointer" },
                                    on: { click: _vm.resetClick },
                                  },
                                  [_vm._v("重置")]
                                ),
                              ],
                              1
                            )
                          : _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "flex-wrap": "nowrap",
                                  "align-items": "center",
                                  "margin-bottom": "10px",
                                },
                              },
                              [
                                _c("DatePicker", {
                                  attrs: {
                                    type: "date",
                                    transfer: "",
                                    placeholder: "开始时间",
                                  },
                                  on: { "on-change": _vm.datePickerStartTime },
                                  model: {
                                    value: _vm.groupPersonSearchForm.startDate,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.groupPersonSearchForm,
                                        "startDate",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "groupPersonSearchForm.startDate",
                                  },
                                }),
                                _vm._v("  ~  "),
                                _c("DatePicker", {
                                  attrs: {
                                    type: "date",
                                    transfer: "",
                                    placeholder: "结束时间",
                                  },
                                  on: { "on-change": _vm.datePickerEndTime },
                                  model: {
                                    value: _vm.groupPersonSearchForm.endDate,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.groupPersonSearchForm,
                                        "endDate",
                                        $$v
                                      )
                                    },
                                    expression: "groupPersonSearchForm.endDate",
                                  },
                                }),
                              ],
                              1
                            ),
                        _c(
                          "Row",
                          {
                            staticStyle: {
                              display: "flex",
                              "flex-wrap": "wrap",
                            },
                          },
                          [
                            _c("Input", {
                              staticStyle: { "margin-bottom": "10px" },
                              attrs: {
                                placeholder: "请输入姓名",
                                clearable: "",
                              },
                              on: {
                                "on-clear": function ($event) {
                                  return _vm.inputSearchChange("")
                                },
                              },
                              nativeOn: {
                                keypress: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  )
                                    return null
                                  return _vm.inputSearchChange.apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                              model: {
                                value: _vm.groupPersonSearchForm.personName,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.groupPersonSearchForm,
                                    "personName",
                                    $$v
                                  )
                                },
                                expression: "groupPersonSearchForm.personName",
                              },
                            }),
                            _vm.drop
                              ? _c("Input", {
                                  staticStyle: { "margin-bottom": "10px" },
                                  attrs: {
                                    placeholder: "请输入体检编号",
                                    clearable: "",
                                  },
                                  on: {
                                    "on-clear": function ($event) {
                                      return _vm.inputSearchChange("")
                                    },
                                  },
                                  nativeOn: {
                                    keypress: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      )
                                        return null
                                      return _vm.inputSearchChange.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.groupPersonSearchForm.testNum,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.groupPersonSearchForm,
                                        "testNum",
                                        $$v
                                      )
                                    },
                                    expression: "groupPersonSearchForm.testNum",
                                  },
                                })
                              : _vm._e(),
                            _vm.drop
                              ? _c("Input", {
                                  staticStyle: { "margin-bottom": "10px" },
                                  attrs: {
                                    placeholder: "请输入单位名称",
                                    clearable: "",
                                  },
                                  on: {
                                    "on-clear": function ($event) {
                                      return _vm.inputSearchChange("")
                                    },
                                  },
                                  nativeOn: {
                                    keypress: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      )
                                        return null
                                      return _vm.inputSearchChange.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.groupPersonSearchForm.dept,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.groupPersonSearchForm,
                                        "dept",
                                        $$v
                                      )
                                    },
                                    expression: "groupPersonSearchForm.dept",
                                  },
                                })
                              : _vm._e(),
                            _c(
                              "Button",
                              {
                                attrs: { type: "primary", size: "small" },
                                on: { click: _vm.inputSearchChange },
                              },
                              [_vm._v("查询")]
                            ),
                            !_vm.queryTime
                              ? _c(
                                  "Button",
                                  {
                                    attrs: { size: "small" },
                                    on: { click: _vm.resetClick },
                                  },
                                  [_vm._v("重置")]
                                )
                              : _vm._e(),
                            _c(
                              "a",
                              {
                                staticClass: "drop-down",
                                on: { click: _vm.dropDown },
                              },
                              [
                                _vm._v(" " + _vm._s(_vm.dropDownContent) + " "),
                                _c("Icon", {
                                  attrs: { type: _vm.dropDownIcon },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "Row",
                          {
                            staticStyle: { "justify-content": "center" },
                            style: {
                              height: "calc(100% - " + _vm.height1 + ")",
                            },
                          },
                          [
                            _vm.personData.length > 0
                              ? _c(
                                  "RadioGroup",
                                  {
                                    staticClass: "radio-group",
                                    model: {
                                      value: _vm.isRadioChecked,
                                      callback: function ($$v) {
                                        _vm.isRadioChecked = $$v
                                      },
                                      expression: "isRadioChecked",
                                    },
                                  },
                                  _vm._l(
                                    _vm.personData,
                                    function (item, index) {
                                      return _c(
                                        "Row",
                                        {
                                          key: index,
                                          staticClass: "row-border",
                                          nativeOn: {
                                            click: function ($event) {
                                              return _vm.radioChooseClick(
                                                item.id,
                                                item.physicalType
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "Col",
                                            {
                                              staticClass: "radio-group-radio",
                                              attrs: { span: 4 },
                                            },
                                            [
                                              _c("Radio", {
                                                attrs: {
                                                  label: item.id,
                                                  disabled: _vm.disRadio,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "Col",
                                            {
                                              staticClass:
                                                "radio-group-content",
                                              attrs: { span: 15 },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    display: "inline-block",
                                                    width: "80px",
                                                    overflow: "hidden",
                                                    "text-overflow": "ellipsis",
                                                    "white-space": "nowrap",
                                                  },
                                                  attrs: {
                                                    title: item.personName,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(item.personName) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "margin-right": "6px",
                                                  },
                                                },
                                                [_vm._v(_vm._s(item.sex))]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    padding: "0 8px 0 4px",
                                                  },
                                                },
                                                [_vm._v(_vm._s(item.age))]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "Col",
                                            { attrs: { span: 5 } },
                                            [
                                              _c(
                                                "Row",
                                                { attrs: { gutter: 6 } },
                                                [
                                                  _c(
                                                    "Col",
                                                    { attrs: { span: 12 } },
                                                    [
                                                      item.physicalType &&
                                                      item.physicalType.indexOf(
                                                        "职业"
                                                      ) != -1
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "border-blue",
                                                            },
                                                            [_vm._v(" 职 ")]
                                                          )
                                                        : _vm._e(),
                                                      item.physicalType &&
                                                      item.physicalType.indexOf(
                                                        "健康"
                                                      ) != -1
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "border-green",
                                                            },
                                                            [_vm._v(" 健 ")]
                                                          )
                                                        : _vm._e(),
                                                      item.physicalType &&
                                                      item.physicalType.indexOf(
                                                        "从业"
                                                      ) != -1
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "border-yellow",
                                                            },
                                                            [_vm._v(" 从 ")]
                                                          )
                                                        : _vm._e(),
                                                      item.physicalType &&
                                                      item.physicalType.indexOf(
                                                        "放射"
                                                      ) != -1
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "border-purple",
                                                            },
                                                            [_vm._v(" 放 ")]
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  ),
                                                  _c(
                                                    "Col",
                                                    { attrs: { span: 12 } },
                                                    [
                                                      item.sporadicPhysical &&
                                                      item.sporadicPhysical == 1
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "border-purple",
                                                            },
                                                            [_vm._v(" 零 ")]
                                                          )
                                                        : _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "border-purple",
                                                            },
                                                            [_vm._v(" 团 ")]
                                                          ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  1
                                )
                              : _c("no-data"),
                            _vm.checkPersonLoading
                              ? _c(
                                  "Spin",
                                  { attrs: { fix: "" } },
                                  [
                                    _c("Icon", {
                                      staticClass: "demo-spin-icon-load",
                                      attrs: {
                                        type: "ios-loading",
                                        size: "18",
                                      },
                                    }),
                                    _c("div", [_vm._v("加载中...")]),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.personData.length > 0
                              ? _c("Page", {
                                  attrs: {
                                    current:
                                      _vm.groupPersonSearchForm.pageNumber,
                                    total: _vm.personTotal,
                                    simple: "",
                                  },
                                  on: { "on-change": _vm.personChangePageNum },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "expand" },
            [
              _c("Icon", {
                staticClass: "icon",
                attrs: { type: _vm.expandIcon, size: "16" },
                on: { click: _vm.changeExpand },
              }),
            ],
            1
          ),
          _c(
            "Col",
            { style: _vm.span },
            [
              _vm.personData.length > 0
                ? _c(
                    "Card",
                    { style: { position: "relative" } },
                    [
                      _c(
                        "Form",
                        { staticClass: "tCheckStationForm" },
                        [
                          _c(
                            "Row",
                            { attrs: { gutter: 10 } },
                            [
                              _c("Col", { attrs: { span: "3" } }, [
                                _c("div", { staticClass: "pic" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "pic-box",
                                      style: {
                                        border: _vm.personInfo.avatar
                                          ? "0 !important;"
                                          : "auto",
                                      },
                                    },
                                    [
                                      _vm.personInfo.avatar
                                        ? _c("img", {
                                            staticStyle: {
                                              height: "100%",
                                              width: "100%",
                                            },
                                            attrs: {
                                              src: _vm.srcImage(
                                                _vm.personInfo.avatar
                                              ),
                                            },
                                            on: { click: _vm.handleView },
                                          })
                                        : _vm._e(),
                                      !_vm.personInfo.avatar
                                        ? _c("img", {
                                            staticStyle: {
                                              height: "100%",
                                              width: "100%",
                                              padding: "5px",
                                            },
                                            attrs: {
                                              src: require("../../../assets/user-avatar.png"),
                                            },
                                          })
                                        : _vm._e(),
                                    ]
                                  ),
                                ]),
                              ]),
                              _c(
                                "Col",
                                { staticClass: "form", attrs: { span: "21" } },
                                [
                                  _c(
                                    "Row",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "align-items": "center",
                                      },
                                    },
                                    [
                                      _c(
                                        "Col",
                                        { attrs: { span: "24" } },
                                        [
                                          _c("step", {
                                            attrs: { index: _vm.currentIndex },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "Row",
                                    [
                                      _c(
                                        "Col",
                                        { attrs: { span: 6 } },
                                        [
                                          _c(
                                            "FormItem",
                                            { attrs: { label: "体检编号" } },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.personInfo.test_num ||
                                                      _vm.personInfo.testNum
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "Col",
                                        { attrs: { span: 6 } },
                                        [
                                          _c(
                                            "FormItem",
                                            { attrs: { label: "身份证号" } },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.personInfo.id_card ||
                                                      _vm.personInfo.idCard
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "Col",
                                        { attrs: { span: 6 } },
                                        [
                                          _c(
                                            "FormItem",
                                            {
                                              attrs: {
                                                label: _vm.setLabel("姓名"),
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.personInfo
                                                      .person_name ||
                                                      _vm.personInfo.personName
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "Col",
                                        { attrs: { span: 6 } },
                                        [
                                          _c(
                                            "FormItem",
                                            {
                                              attrs: {
                                                label: _vm.setLabel("性别"),
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.personInfo.sex) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "Row",
                                    [
                                      _c(
                                        "Col",
                                        { attrs: { span: 6 } },
                                        [
                                          _c(
                                            "FormItem",
                                            {
                                              attrs: {
                                                label: _vm.setLabel("年龄"),
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.personInfo.age
                                                      ? _vm.personInfo.age +
                                                          "岁"
                                                      : ""
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "Col",
                                        { attrs: { span: 6 } },
                                        [
                                          _c(
                                            "FormItem",
                                            { attrs: { label: "体检类型" } },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.personInfo
                                                      .physical_type ||
                                                      _vm.personInfo
                                                        .physicalType
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      (_vm.personInfo.physical_type ||
                                        _vm.personInfo.physicalType) ==
                                        "职业体检" ||
                                      (_vm.personInfo.physical_type ||
                                        _vm.personInfo.physicalType) ==
                                        "放射体检"
                                        ? _c(
                                            "Col",
                                            { attrs: { span: 6 } },
                                            [
                                              _c(
                                                "FormItem",
                                                {
                                                  attrs: { label: "危害因素" },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.personInfo
                                                          .hazard_factors_text ||
                                                          _vm.personInfo
                                                            .hazardFactorsText
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      (_vm.personInfo.physical_type ||
                                        _vm.personInfo.physicalType) ==
                                        "职业体检" ||
                                      (_vm.personInfo.physical_type ||
                                        _vm.personInfo.physicalType) ==
                                        "放射体检"
                                        ? _c(
                                            "Col",
                                            { attrs: { span: 6 } },
                                            [
                                              _c(
                                                "FormItem",
                                                {
                                                  attrs: { label: "检查种类" },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.personInfo
                                                          .work_state_text ||
                                                          _vm.personInfo
                                                            .workStateText
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      (_vm.personInfo.physical_type ||
                                        _vm.personInfo.physicalType) ==
                                      "健康体检"
                                        ? _c(
                                            "Col",
                                            { attrs: { span: 6 } },
                                            [
                                              _c(
                                                "FormItem",
                                                {
                                                  attrs: { label: "订单类型" },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.personInfo
                                                          .oldGroupId ||
                                                          _vm.personInfo
                                                            .old_group_id
                                                          ? "团单"
                                                          : "个单"
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "Row",
                                    [
                                      _vm.personInfo.physicalType ==
                                        "职业体检" ||
                                      _vm.personInfo.physical_type ==
                                        "职业体检" ||
                                      _vm.personInfo.physicalType ==
                                        "放射体检" ||
                                      _vm.personInfo.physical_type ==
                                        "放射体检" ||
                                      _vm.personInfo.physicalType ==
                                        "健康体检" ||
                                      (_vm.personInfo.physical_type ==
                                        "健康体检" &&
                                        (_vm.personInfo.oldGroupId ||
                                          _vm.personInfo.old_group_id))
                                        ? _c(
                                            "Col",
                                            { attrs: { span: 6 } },
                                            [
                                              _c(
                                                "FormItem",
                                                {
                                                  attrs: { label: "单位名称" },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.personInfo
                                                          .unit_name ||
                                                          _vm.personInfo
                                                            .unitName
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      (_vm.personInfo.physical_type ||
                                        _vm.personInfo.physicalType) ==
                                        "职业体检" ||
                                      (_vm.personInfo.physical_type ||
                                        _vm.personInfo.physicalType) ==
                                        "放射体检"
                                        ? _c(
                                            "Col",
                                            { attrs: { span: 6 } },
                                            [
                                              _c(
                                                "FormItem",
                                                { attrs: { label: "职业病" } },
                                                [
                                                  _c(
                                                    "Tooltip",
                                                    {
                                                      attrs: {
                                                        transfer: "",
                                                        "max-width": "200",
                                                        content:
                                                          _vm.personInfo
                                                            .occupational_diseases ||
                                                          _vm.personInfo
                                                            .occupationalDiseases,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.personInfo
                                                              .occupational_diseases ||
                                                              _vm.personInfo
                                                                .occupationalDiseases
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      (_vm.personInfo.physical_type ||
                                        _vm.personInfo.physicalType) ==
                                        "职业体检" ||
                                      (_vm.personInfo.physical_type ||
                                        _vm.personInfo.physicalType) ==
                                        "放射体检"
                                        ? _c(
                                            "Col",
                                            { attrs: { span: 6 } },
                                            [
                                              _c(
                                                "FormItem",
                                                {
                                                  attrs: {
                                                    label: "职业禁忌证",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "Tooltip",
                                                    {
                                                      attrs: {
                                                        transfer: "",
                                                        "max-width": "200",
                                                        content:
                                                          _vm.personInfo
                                                            .occupational_taboo ||
                                                          _vm.personInfo
                                                            .occupationalTaboo,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.personInfo
                                                              .occupational_taboo ||
                                                              _vm.personInfo
                                                                .occupationalTaboo
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "Col",
                                        { attrs: { span: 6 } },
                                        [
                                          _c(
                                            "FormItem",
                                            { attrs: { label: "登记时间" } },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.personInfo
                                                      .regist_date ||
                                                      _vm.personInfo.registDate
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          !_vm.checkFlag
                            ? _c(
                                "div",
                                {
                                  staticStyle: {
                                    bottom: "10px",
                                    display: "flex",
                                    "margin-top": "5px",
                                  },
                                },
                                [
                                  _vm.isShowCheck
                                    ? _c(
                                        "Button",
                                        {
                                          staticStyle: {
                                            "margin-top": "10px",
                                            width: "150px",
                                          },
                                          attrs: {
                                            loading: _vm.checkLoading,
                                            type: "success",
                                          },
                                          on: { click: _vm.handleConfirm },
                                        },
                                        [
                                          _c("Icon", {
                                            attrs: { type: "md-checkmark" },
                                          }),
                                          _vm._v(" 到检确认 "),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.codeShow
                                    ? _c(
                                        "Button",
                                        {
                                          staticStyle: {
                                            "margin-top": "10px",
                                            width: "150px",
                                          },
                                          attrs: { type: "success" },
                                          on: { click: _vm.codeConfirm },
                                        },
                                        [
                                          _c("Icon", {
                                            attrs: { type: "ios-eye-outline" },
                                          }),
                                          _vm._v(" 样本条码查看 "),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.isShowButton
                                    ? _c(
                                        "Button",
                                        {
                                          staticStyle: {
                                            "margin-top": "10px",
                                            width: "150px",
                                          },
                                          attrs: {
                                            loading: _vm.checkLoading,
                                            type: "success",
                                          },
                                          on: { click: _vm.DataBaseClick },
                                        },
                                        [
                                          _c("Icon", {
                                            attrs: { type: "ios-cloud-upload" },
                                          }),
                                          _vm._v(" 单机版数据导入 "),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.isQuitShow
                                    ? _c(
                                        "Button",
                                        {
                                          staticStyle: {
                                            "margin-top": "10px",
                                            width: "150px",
                                          },
                                          attrs: {
                                            type: "error",
                                            loading: _vm.checkLoading,
                                          },
                                          on: { click: _vm.handleCheckConfirm },
                                        },
                                        [
                                          _c("Icon", {
                                            attrs: { type: "ios-close-circle" },
                                          }),
                                          _vm._v(" 弃检项目 "),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.isCancelShow
                                    ? _c(
                                        "Button",
                                        {
                                          staticStyle: {
                                            "margin-top": "10px",
                                            width: "150px",
                                          },
                                          attrs: {
                                            type: "primary",
                                            loading: _vm.cancelLoading,
                                          },
                                          on: {
                                            click: _vm.CancelDiscardInspection,
                                          },
                                        },
                                        [
                                          _c("Icon", {
                                            attrs: {
                                              type: "ios-checkmark-circle-outline",
                                            },
                                          }),
                                          _vm._v(" 取消弃检 "),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.personData.length > 0 && _vm.showOtherInfo
                ? _c(
                    "Card",
                    {
                      staticStyle: {
                        "margin-top": "10px",
                        overflow: "auto",
                        height: "calc(100vh - 271px)",
                      },
                    },
                    [
                      _vm.checkFlag && _vm.showOtherInfo
                        ? _c(
                            "div",
                            { staticStyle: { height: "100%" } },
                            [
                              _c("otherInfo", {
                                attrs: { personInfo: _vm.personInfo },
                                on: { handleSearch: _vm.getPersonByOfficeId },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  )
                : _vm._e(),
              _vm.personData.length > 0 && !_vm.showOtherInfo
                ? _c(
                    "Card",
                    {
                      staticStyle: {
                        "margin-top": "10px",
                        overflow: "auto",
                        height: "calc(100vh - 318px)",
                      },
                    },
                    [
                      _vm.checkFlag && !_vm.showOtherInfo
                        ? _c(
                            "div",
                            [
                              _c(
                                "Spin",
                                {
                                  staticStyle: { top: "50px" },
                                  attrs: { fix: "" },
                                },
                                [
                                  _c("Icon", {
                                    staticClass: "demo-spin-icon-load",
                                    attrs: { type: "ios-loading", size: "18" },
                                  }),
                                  _c("div", [_vm._v("加载中...")]),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      !_vm.checkFlag
                        ? _c(
                            "div",
                            {
                              staticClass: "tCheckStationForm",
                              style: {
                                borderTop:
                                  _vm.tabsArr.length > 0
                                    ? ""
                                    : "1px dashed #ccc",
                              },
                              attrs: { id: "tab" },
                            },
                            [
                              _vm.tabsArr.length > 0
                                ? _c(
                                    "el-tabs",
                                    {
                                      staticStyle: {
                                        width: "100%",
                                        "margin-top": "10px",
                                      },
                                      attrs: { type: "border-card" },
                                      on: { "tab-click": _vm.tabsClick },
                                      model: {
                                        value: _vm.tabsValue,
                                        callback: function ($$v) {
                                          _vm.tabsValue = $$v
                                        },
                                        expression: "tabsValue",
                                      },
                                    },
                                    _vm._l(_vm.tabsArr, function (item, index) {
                                      return _c(
                                        "el-tab-pane",
                                        {
                                          key: index,
                                          staticStyle: {
                                            "margin-bottom": "10px",
                                          },
                                          attrs: {
                                            lazy: true,
                                            disabled: _vm.tabShow,
                                            label: _vm.initTabName(item.name),
                                            name: item.id,
                                          },
                                        },
                                        [
                                          item.id == _vm.tabsValue
                                            ? _c(
                                                "div",
                                                [
                                                  item.isFile == "是"
                                                    ? _c(
                                                        "div",
                                                        [
                                                          _c(
                                                            "Row",
                                                            {
                                                              staticStyle: {
                                                                height: "363px",
                                                                border:
                                                                  "1px solid #DCDEE2",
                                                                "border-top":
                                                                  "0",
                                                                overflow:
                                                                  "hidden",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "Col",
                                                                {
                                                                  staticStyle: {
                                                                    "justify-content":
                                                                      "center",
                                                                    "align-items":
                                                                      "center",
                                                                  },
                                                                  attrs: {
                                                                    span: "24",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    [
                                                                      _c(
                                                                        "Table",
                                                                        {
                                                                          key: Math.random(),
                                                                          attrs:
                                                                            {
                                                                              loading:
                                                                                _vm.loading,
                                                                              border:
                                                                                "",
                                                                              columns:
                                                                                _vm.getColumnsYX(),
                                                                              sortable:
                                                                                "custom",
                                                                              data: _vm.data,
                                                                              "row-class-name":
                                                                                _vm.rowClassName,
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      directives:
                                                                        [
                                                                          {
                                                                            name: "viewer",
                                                                            rawName:
                                                                              "v-viewer",
                                                                            value:
                                                                              {
                                                                                movable: false,
                                                                                zIndex: 7000,
                                                                              },
                                                                            expression:
                                                                              "{movable: false,zIndex:7000}",
                                                                          },
                                                                        ],
                                                                      staticStyle:
                                                                        {
                                                                          height:
                                                                            "80%",
                                                                          width:
                                                                            "100%",
                                                                          display:
                                                                            "flex",
                                                                          "justify-content":
                                                                            "center",
                                                                          "align-items":
                                                                            "center",
                                                                        },
                                                                    },
                                                                    _vm._l(
                                                                      _vm.pacsData,
                                                                      function (
                                                                        item,
                                                                        index
                                                                      ) {
                                                                        return _c(
                                                                          "img",
                                                                          {
                                                                            key: index,
                                                                            staticStyle:
                                                                              {
                                                                                border:
                                                                                  "1px dashed #ccc",
                                                                                "margin-right":
                                                                                  "20px",
                                                                                width:
                                                                                  "25vw",
                                                                                height:
                                                                                  "27vh",
                                                                              },
                                                                            attrs:
                                                                              {
                                                                                src: item,
                                                                              },
                                                                          }
                                                                        )
                                                                      }
                                                                    ),
                                                                    0
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  item.isFile == "否" ||
                                                  item.isFile == null ||
                                                  item.isFile == ""
                                                    ? _c(
                                                        "div",
                                                        [
                                                          _c("Table", {
                                                            key: Math.random(),
                                                            attrs: {
                                                              loading:
                                                                _vm.loading,
                                                              border: "",
                                                              columns:
                                                                item.name.indexOf(
                                                                  "肺功能"
                                                                ) > -1
                                                                  ? _vm.getColumnsFGN()
                                                                  : _vm.getColumns(),
                                                              sortable:
                                                                "custom",
                                                              data: _vm.data,
                                                              "row-class-name":
                                                                _vm.rowClassName,
                                                              height: "410",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _c(
                                                    "Form",
                                                    {
                                                      staticStyle: {
                                                        margin: "20px 2px 0",
                                                        "border-bottom": "0",
                                                      },
                                                      attrs: {
                                                        "label-width": 30,
                                                        inline: "",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "Row",
                                                        {
                                                          staticStyle: {
                                                            "flex-wrap":
                                                              "nowrap",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "Col",
                                                            {
                                                              staticStyle: {
                                                                position:
                                                                  "relative",
                                                              },
                                                              attrs: {
                                                                span: "10",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "Button",
                                                                {
                                                                  staticStyle: {
                                                                    position:
                                                                      "absolute",
                                                                    right:
                                                                      "-4px",
                                                                    bottom:
                                                                      "1px",
                                                                    "z-index":
                                                                      "8",
                                                                    height:
                                                                      "22px",
                                                                    padding:
                                                                      "0 10px",
                                                                  },
                                                                  attrs: {
                                                                    type: "primary",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.handleWordChoose(
                                                                          "zdtx",
                                                                          item
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [_vm._v("选 ")]
                                                              ),
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticStyle: {
                                                                    "font-size":
                                                                      "14px",
                                                                    "font-weight":
                                                                      "600",
                                                                    "margin-bottom":
                                                                      "6px",
                                                                  },
                                                                },
                                                                [
                                                                  item.isFile ==
                                                                  "是"
                                                                    ? _c(
                                                                        "span",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              color:
                                                                                "red",
                                                                              "font-size":
                                                                                "16px",
                                                                              "margin-right":
                                                                                "6px",
                                                                              position:
                                                                                "relative",
                                                                              top: "4px",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "*"
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  _vm._v(
                                                                    "体检提醒或影像所见"
                                                                  ),
                                                                ]
                                                              ),
                                                              _c("Input", {
                                                                attrs: {
                                                                  type: "textarea",
                                                                  placeholder:
                                                                    "输入多个以;分隔",
                                                                  autosize: {
                                                                    maxRows: 6,
                                                                    minRows: 6,
                                                                  },
                                                                },
                                                                on: {
                                                                  "on-focus":
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.handlediagnoseTipData(
                                                                        item
                                                                      )
                                                                    },
                                                                },
                                                                model: {
                                                                  value:
                                                                    item
                                                                      .departResult
                                                                      .diagnoseTip,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        item.departResult,
                                                                        "diagnoseTip",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "item.departResult.diagnoseTip",
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                          _c("Col", {
                                                            attrs: {
                                                              span: "4",
                                                            },
                                                          }),
                                                          _c(
                                                            "Col",
                                                            {
                                                              staticStyle: {
                                                                "margin-left":
                                                                  "16px",
                                                              },
                                                              attrs: {
                                                                span: "10",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "Button",
                                                                {
                                                                  staticStyle: {
                                                                    position:
                                                                      "absolute",
                                                                    right:
                                                                      "-4px",
                                                                    bottom:
                                                                      "1px",
                                                                    "z-index":
                                                                      "8",
                                                                    height:
                                                                      "22px",
                                                                    padding:
                                                                      "0 10px",
                                                                  },
                                                                  attrs: {
                                                                    disabled:
                                                                      _vm.disRadio,
                                                                    type: "primary",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.handleWordChoose(
                                                                          "zdxj",
                                                                          item
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [_vm._v("选 ")]
                                                              ),
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticStyle: {
                                                                    "font-size":
                                                                      "14px",
                                                                    "font-weight":
                                                                      "600",
                                                                    "margin-bottom":
                                                                      "6px",
                                                                  },
                                                                },
                                                                [
                                                                  item.isFile ==
                                                                  "是"
                                                                    ? _c(
                                                                        "span",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              color:
                                                                                "red",
                                                                              "font-size":
                                                                                "16px",
                                                                              "margin-right":
                                                                                "6px",
                                                                              position:
                                                                                "relative",
                                                                              top: "4px",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "*"
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  _vm._v(
                                                                    "体检小结"
                                                                  ),
                                                                ]
                                                              ),
                                                              _c("Input", {
                                                                attrs: {
                                                                  type: "textarea",
                                                                  placeholder:
                                                                    "输入多个以;分隔",
                                                                  autosize: {
                                                                    maxRows: 6,
                                                                    minRows: 6,
                                                                  },
                                                                },
                                                                on: {
                                                                  "on-focus":
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.handleCrisisData(
                                                                        item
                                                                      )
                                                                    },
                                                                  "on-change":
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.diagnoseSumChange(
                                                                        item
                                                                      )
                                                                    },
                                                                },
                                                                model: {
                                                                  value:
                                                                    item
                                                                      .departResult
                                                                      .diagnoseSum,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        item.departResult,
                                                                        "diagnoseSum",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "item.departResult.diagnoseSum",
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "Form",
                                                    {
                                                      staticStyle: {
                                                        "margin-top": "30px",
                                                      },
                                                      attrs: {
                                                        "label-width": 90,
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "Row",
                                                        {
                                                          staticStyle: {
                                                            "flex-wrap":
                                                              "nowrap",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "Col",
                                                            {
                                                              attrs: {
                                                                span: "5",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "FormItem",
                                                                {
                                                                  attrs: {
                                                                    label:
                                                                      "检查医生",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "Select",
                                                                    {
                                                                      staticClass:
                                                                        "input",
                                                                      attrs: {
                                                                        filterable: true,
                                                                        "label-in-value":
                                                                          "",
                                                                        transfer:
                                                                          "",
                                                                      },
                                                                      on: {
                                                                        "on-select":
                                                                          _vm.doctorSelectChange,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          item
                                                                            .departResult
                                                                            .createId,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              item.departResult,
                                                                              "createId",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "item.departResult.createId",
                                                                      },
                                                                    },
                                                                    _vm._l(
                                                                      _vm.userArray,
                                                                      function (
                                                                        itemD,
                                                                        index
                                                                      ) {
                                                                        return _c(
                                                                          "Option",
                                                                          {
                                                                            key: index,
                                                                            attrs:
                                                                              {
                                                                                value:
                                                                                  itemD.id,
                                                                                label:
                                                                                  itemD.nickname,
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  itemD.nickname
                                                                                ) +
                                                                                " "
                                                                            ),
                                                                          ]
                                                                        )
                                                                      }
                                                                    ),
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "Col",
                                                            {
                                                              attrs: {
                                                                span: "5",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "FormItem",
                                                                {
                                                                  attrs: {
                                                                    label:
                                                                      "检查日期",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "DatePicker",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          width:
                                                                            "175px",
                                                                        },
                                                                      attrs: {
                                                                        type: "datetime",
                                                                        transfer:
                                                                          "",
                                                                        placeholder:
                                                                          "请选择检查日期",
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          item
                                                                            .departResult
                                                                            .checkDate,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              item.departResult,
                                                                              "checkDate",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "item.departResult.checkDate",
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _c("Col", {
                                                            attrs: {
                                                              span: "4",
                                                            },
                                                          }),
                                                          _c(
                                                            "Col",
                                                            [
                                                              _c(
                                                                "ButtonGroup",
                                                                {
                                                                  staticStyle: {
                                                                    display:
                                                                      "flex",
                                                                    "justify-content":
                                                                      "center",
                                                                    "margin-left":
                                                                      "18px",
                                                                  },
                                                                },
                                                                [
                                                                  item.isFile ==
                                                                  "是"
                                                                    ? _c(
                                                                        "Button",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              type: "primary",
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.uploadImageClick(
                                                                                  item.officeId
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " 上传图片 "
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  item.name.indexOf(
                                                                    "测听"
                                                                  ) > -1 &&
                                                                  _vm.isShowCalculationButton
                                                                    ? _c(
                                                                        "Button",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              type: "primary",
                                                                              loading:
                                                                                _vm.syncLoading,
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              _vm.calculation,
                                                                          },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "计算偏移值 "
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  item.specimen !=
                                                                  null
                                                                    ? _c(
                                                                        "Button",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              disabled:
                                                                                item.status ==
                                                                                  1 ||
                                                                                item.status ==
                                                                                  2
                                                                                  ? false
                                                                                  : true,
                                                                              type: "primary",
                                                                              loading:
                                                                                _vm.syncLoading,
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              _vm.syncResult,
                                                                          },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "同步结果 "
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  item
                                                                    .departResult
                                                                    .id ||
                                                                  _vm.indxArr.indexOf(
                                                                    index
                                                                  ) > -1
                                                                    ? _c(
                                                                        "Button",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              disabled:
                                                                                item.status ==
                                                                                  1 ||
                                                                                item.status ==
                                                                                  2
                                                                                  ? false
                                                                                  : true,
                                                                              type: "primary",
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              _vm.handleViewTemplate,
                                                                          },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "报告预览 "
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  _c(
                                                                    "Button",
                                                                    {
                                                                      attrs: {
                                                                        disabled:
                                                                          item.status ==
                                                                            1 ||
                                                                          item.status ==
                                                                            2
                                                                            ? false
                                                                            : true,
                                                                        type: "success",
                                                                        loading:
                                                                          _vm.saveLoading,
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.handleSave(
                                                                              index
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "保存结果 "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ]
                                      )
                                    }),
                                    1
                                  )
                                : _vm._e(),
                              _vm.tabsArr.length == 0
                                ? _c(
                                    "Spin",
                                    {
                                      staticStyle: { top: "50px" },
                                      style: {
                                        border:
                                          _vm.tabsArr.length == 0
                                            ? "1px solid #ccc"
                                            : "",
                                        height:
                                          _vm.tabsArr.length == 0
                                            ? "200px"
                                            : "",
                                      },
                                      attrs: { fix: "" },
                                    },
                                    [
                                      _c("Icon", {
                                        staticClass: "demo-spin-icon-load",
                                        attrs: {
                                          type: "ios-loading",
                                          size: "18",
                                        },
                                      }),
                                      _c("div", [_vm._v("加载中...")]),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("drawer", {
                        attrs: {
                          isSearchPositive: _vm.isUserOfficeTerm
                            ? false
                            : _vm.isSearchPositive,
                          modalTitle: _vm.title,
                          type: _vm.type,
                          inspectType:
                            _vm.personInfo.physical_type ||
                            _vm.personInfo.physicalType,
                          officeId: _vm.officeId,
                          hazardFactors:
                            _vm.personInfo.hazard_factors ||
                            _vm.personInfo.hazardFactors,
                          hazardFactorsText:
                            _vm.personInfo.hazard_factors_text ||
                            _vm.personInfo.hazardFactorsText,
                        },
                        on: { getSelectedWords: _vm.getSelectedWords },
                        model: {
                          value: _vm.wordChooseShow,
                          callback: function ($$v) {
                            _vm.wordChooseShow = $$v
                          },
                          expression: "wordChooseShow",
                        },
                      }),
                      _c("preview-template", {
                        attrs: { personInfo: _vm.personInfo },
                        model: {
                          value: _vm.previewShow,
                          callback: function ($$v) {
                            _vm.previewShow = $$v
                          },
                          expression: "previewShow",
                        },
                      }),
                      _c("review", {
                        attrs: {
                          modalTitle: _vm.title,
                          groupItemId: _vm.groupItemId,
                          personInfo: _vm.personInfo,
                        },
                        on: { getItemDataByGroupId: _vm.getItemDataByGroupId },
                        model: {
                          value: _vm.reviewShow,
                          callback: function ($$v) {
                            _vm.reviewShow = $$v
                          },
                          expression: "reviewShow",
                        },
                      }),
                      _c("data-base-upload", {
                        model: {
                          value: _vm.dataBaseUploadShow,
                          callback: function ($$v) {
                            _vm.dataBaseUploadShow = $$v
                          },
                          expression: "dataBaseUploadShow",
                        },
                      }),
                      _c("upload-image", {
                        on: { handleUploadImage: _vm.handleUploadImage },
                        model: {
                          value: _vm.isShowUploadImage,
                          callback: function ($$v) {
                            _vm.isShowUploadImage = $$v
                          },
                          expression: "isShowUploadImage",
                        },
                      }),
                      _c("sample-code", {
                        attrs: {
                          data: _vm.tabsArr,
                          personInfo: _vm.personInfo,
                        },
                        model: {
                          value: _vm.sampleCodeShow,
                          callback: function ($$v) {
                            _vm.sampleCodeShow = $$v
                          },
                          expression: "sampleCodeShow",
                        },
                      }),
                      _vm.personInfoLoading
                        ? _c("Spin", { attrs: { fix: "" } })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.personData.length == 0
                ? _c(
                    "Card",
                    [
                      _c("no-data", {
                        staticStyle: {
                          height: "calc(100vh - 128px)",
                          overflow: "hidden",
                          display: "flex",
                          "flex-direction": "row",
                          "justify-content": "center",
                        },
                        attrs: { width: "150" },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "Modal",
        {
          attrs: { title: "弃检原因", "mask-closable": false, closable: false },
          model: {
            value: _vm.abandonRensonShow,
            callback: function ($$v) {
              _vm.abandonRensonShow = $$v
            },
            expression: "abandonRensonShow",
          },
        },
        [
          _c(
            "label",
            [
              _c("Input", {
                attrs: {
                  type: "textarea",
                  autosize: { minRows: 2, maxRows: 5 },
                  placeholder: "请输入弃检原因...",
                },
                model: {
                  value: _vm.abandonRenson,
                  callback: function ($$v) {
                    _vm.abandonRenson = $$v
                  },
                  expression: "abandonRenson",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "Button",
                {
                  attrs: { type: "text" },
                  on: {
                    click: function ($event) {
                      _vm.abandonRensonShow = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _c(
                "Button",
                {
                  attrs: { type: "primary", loading: _vm.checkLoading },
                  on: { click: _vm.abandonRensonClick },
                },
                [_vm._v("提交")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }